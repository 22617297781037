<template>
  <div>
    <v-card>
      <!-- <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line hide-details></v-text-field>
      </v-card-title> -->
      <v-data-table disable-pagination hide-default-footer :headers="headers" :items="contributions" :loading="loading" class="elevation-1">

        <template v-slot:item.last_name="{ item }">
           <router-link :to="{ name: 'inscriptionDetail', params: { tutor_id: item.tutor_id }}" >{{item.last_name}}</router-link>
        </template>

        <template v-slot:item.first_name="{ item }">
           <router-link :to="{ name: 'inscriptionDetail', params: { tutor_id: item.tutor_id }}" >{{item.first_name}}</router-link>
        </template>

        <template v-slot:item.restant="{ item }">
           {{calculateAmount(item)}}
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data: () => ({
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 4,
      sortBy: "created_at",
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16]
    },
    search: "",
    contributions: [],
    totalContributions: 0,
    error: null,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: [true]
    },
    loading: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Tutor" : "Edit Tutor";
    },
    headers() {
      return [
        { text: this.$t("lastname"), value: "last_name", sortable: false },
        { text: this.$t("firstname"), value: "first_name", sortable: false },
        { text: "Montant total", value: "amount_total", sortable: false },
         { text: "Montant payé", value: "amount_payed", sortable: false },
        { text: "Montant restant", value: "restant", sortable: false }
      ];
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi().then(data => {
          this.contributions = data.items;
          this.totalContributions = data.total;
        });
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi().then(data => {
      this.contributions = data.items;
      this.totalContributions = data.total;
    });
  },

  methods: { 
    calculateAmount(contribution) {
      return contribution.amount_total - contribution.amount_payed;
    },
    loadingContributions() {
      this.getDataFromApi().then(data => {
        this.contributions = data.items;
        this.totalContributions = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        /* let search = this.search.trim().toLowerCase();*/

        let items = this.getJsonData().then(response => {
          items = response;

          /* if (this.search) {
            items = items.filter(item => {
              return Object.values(item)
                .join(",")
                .toLowerCase()
                .includes(this.search);
            });
          }*/

          /* if (this.options.sortBy) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy];
              const sortB = b[sortBy];

              if (descending) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }*/

          /*if (rowsPerPage > 0) {
            items = items.slice((page - 1) * rowsPerPage, page * rowsPerPage);
          }*/

          const total = response.total;

          setTimeout(() => {
            this.loading = false;
            resolve({
              items,
              total
            });
          }, 300);
        });
      });
    },

    getJsonData() {
      //  Aqui pra ficar completo falta o parametro de filtro que a
      // a API so SWAPI não me da...
      var sortDesc = null;
      if (this.options.sortDesc[0]) {
        sortDesc = "asc";
      } else {
        sortDesc = "desc";
      }
      console.log(`/contributions?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`);
      return APIServices.get(`/contributions`)
        .then(function(response) {
          var result = response.data;
         // console.log(result);
          return result;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },

    initialize() {
      this.loading = true;
      APIServices.get("/contributions")
        .then(response => {
          console.log(response);
          this.contributions = response.data.data;
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
